import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { Box } from 'rebass';
import slugify from '@sindresorhus/slugify';
import REGIONS from '../locations/regions.json';

import Layout from '../components/Layout';
import { CTA } from '../components/ui/CTA';
import Button from '../components/ui/Button';
import { ArticleContainer } from '../components/ui/ArticleContainer';

function RegionTemplate({ helmet }) {
  return (
    <Layout
      rightsidebarContent={
        <div className="flex justify-center py-2 mt-12 shadow-md xl:shadow-none bg-indigo-50 xl:bg-transparent xl:mr-8 xl:p-0">
          <a href="/devis-gardiennage?utm_campaign=agent-de-securite&utm_source=articles">
            <Button variant="secondary" className="xl:animate-pump">
              Demander un devis gratuit
            </Button>
          </a>
        </div>
      }
    >
      <ArticleContainer>
        <div>
          {helmet || ''}
          <h1>
            <span className="block mb-4 text-4xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
              Agences de sécurité dans votre région
            </span>
          </h1>
          <article className="mx-auto prose prose-lg text-gray-600 prose-indigo">
            <p>
              Vous recherchez une{' '}
              <Link to="/agence-de-securite-privee-gardiennage-surveillance-et-surete">
                agence de sécurité
              </Link>{' '}
              privée pour des prestations de gardiennage et de surveillance ?
              Nous pouvons répondre à vos besoins en sécurisation grâce à notre
              réseau de plus de 500 agences de sécurité privée.
            </p>
            <CTA href="/devis-gardiennage?utm_campaign=agent-de-securite&utm_source=articles">
              Demander un devis gratuit
            </CTA>
            <p>Cliquez sur votre région :</p>
            <Box my={3}>
              <ul>
                {REGIONS.map((region) => (
                  <li key={region.code}>
                    <Box my={1}>
                      <Link to={`/agent-de-securite/${slugify(region.nom)}/`}>
                        {region.nom}
                      </Link>
                    </Box>
                  </li>
                ))}
              </ul>
            </Box>
            <p>
              Lead Advisor Security est une plateforme dédiée à la sécurité
              privée rassemblant plus de 500 entreprises de sécurité privée.
              Elle vous permet de recevoir plusieurs devis comparatifs pour une
              seule demande, puis de choisir la meilleure proposition, celle
              étant la plus sécurisante et proposant le meilleur rapport
              qualité/prix. La plateforme vous permet d’échanger et d’établir un
              contact commercial avec les agences intéressées pour répondre à
              votre demande.
            </p>
            <p>
              Vous pouvez utiliser la plateforme pour les demandes suivantes :
            </p>
            <ul>
              <li>
                Agents de Prévention et de Sécurité (APS), agents d’accueil,
                agents cynophiles, et agents de sécurité évènementiels pour
                sécuriser vos locaux et vos évènements
              </li>

              <li>
                Agent de sécurité incendie SSIAP pour contrôler le
                fonctionnement du matériel incendie, et alerter puis guider les
                secours en cas de feu
              </li>

              <li>
                Rondier intervenants pour réaliser des rondes préventives
                régulières en véhicules sur les sites à surveiller
              </li>

              <li>Inspecteur de magasin pour un commerce</li>

              <li>Système de contrôle d’accès électronique</li>

              <li>Système de vidéoprotection</li>

              <li>Système d’alarme intrusion </li>

              <li>Système d’alarme incendie</li>

              <li>Portique de sécurité et antivols</li>
            </ul>
          </article>
        </div>
      </ArticleContainer>
    </Layout>
  );
}

const AgentDeSecuriteTemplate = ({ pageContext, data, ...props }) => {
  const { site } = data;
  const title = `Agences de sécurité dans votre région - Lead Advisor`;
  const description = `Vous avez besoin des services d’une agence de sécurité dans votre région ? Recevez des devis gratuits pour des prestations d’agents de sécurité sur Lead Advisor Security.`;

  return (
    <RegionTemplate
      helmet={
        <Helmet titleTemplate="%s">
          <title>{title}</title>
          <link
            rel="canonical"
            href={`${site.siteMetadata.siteUrl}/articles/agent-de-securite`}
          />
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta
            property="og:url"
            content={`${site.siteMetadata.siteUrl}/articles/agent-de-securite`}
          />
          <meta name="author" content="David Khoy de Lead Advisor" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@lead_advisor" />
          <meta name="twitter:title" content={title} />
          <meta
            name="twitter:url"
            content={`${site.siteMetadata.siteUrl}/articles/agent-de-securite`}
          />
          <meta name="twitter:description" content={description} />
          <meta
            name="twitter:image"
            content={`${site.siteMetadata.siteUrl}/articles/img/og-image.jpg`}
          />

          <script type="application/ld+json">
            {`
{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite",
    "name": "Agent de sécurité"
  }]
}
`}
          </script>
        </Helmet>
      }
    />
  );
};

export const pageQuery = graphql`
  query regionListSiteMetadata {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default AgentDeSecuriteTemplate;
